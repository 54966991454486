<template>
  <SprintAnswerBackground
    @backTraining="backTraining"
    :progresses="engTrainingProgresses"
    :items="getTableItems()"
    :questionInfos="questionInfos"
    @seeAnswer="seeAnswer"
    :isRedo="isRedo"
    :mediaUrls="mediaUrls"
  >
    <b-row class="answer-title">
      <b-col class="text-left" cols="9">
        <span>{{ questionTypeName }}</span>
      </b-col>
      <GlobalAnswerStates
        v-if="!showLog"
        ref="group"
        :groupAnswerStates="groupAnswerStates"
      >
      </GlobalAnswerStates>
      <GlobalButton
        v-if="showLog"
        buttonType="yellow"
        buttonText="返回记录页"
        @afterClick="$router.go(-1)"
      ></GlobalButton>
      <GlbalTopicPopover
        :stemStatement="question.statement"
      ></GlbalTopicPopover>
    </b-row>
    <b-row :style="showLog ? 'min-height: 15rem' : 'min-height: 25rem'">
      <b-col style="padding: 10px 17px 2rem 17px">
        <div
          :class="showLog ? 'pass-through-class' : 'Nopass-through'"
          style="display: flex"
        >
          <span v-if="!showLog" class="question-stem-number">{{
            question.questionNumber || questionNumber
          }}</span>
          <EnglishTestQuestion
            :examQuestion="question"
            @changeRadio="changeRadio"
            :key="question.questionId"
            :isVariation="false"
            :errAnswer="errAnswer"
          />
        </div>
      </b-col>
    </b-row>
    <b-row style="padding: 2rem 0" v-if="!showLog">
      <b-col class="text-right">
        <GlobalButton
          buttonType="round"
          buttonText="提交答案"
          @afterClick="checkSumit"
          :disabled="canNext || submiting"
        >
          <b-spinner v-if="submiting" slot="loading" small></b-spinner>
        </GlobalButton>
        <GlobalButton
          buttonType="round-text"
          buttonText="下一题"
          @afterClick="nextQuestion"
          :disabled="!canNext"
        ></GlobalButton>
      </b-col>
    </b-row>
    <PersonalNotes v-else></PersonalNotes>
    <b-row v-if="isAnswerKeyShow" align-h="between" class="answer-analysis">
      <b-col align-self="start" cols="6" style="color: #2cb774"
        >答题解析及素养提升：</b-col
      >
      <b-col
        @click="isAnswerKeyClass = !isAnswerKeyClass"
        align-self="start"
        cols="2"
        class="view-all"
      >
        {{ isAnswerKeyClass ? "收起信息" : "查看全部" }}
        <span
          :class="[
            'iconfont',
            isAnswerKeyClass ? 'icon-jiantou_xia' : 'icon-jiantou8',
          ]"
        ></span>
      </b-col>
      <b-col
        cols="12"
        class="analysis-class"
        :class="isAnswerKeyClass ? 'isAnswerKey-class' : ''"
        style=""
      >
        <div v-if="isRedo">
          <b-row
            v-for="(questionAnswerAnalysis, index) in logDetail.questions"
            :key="index"
          >
            <b-row class="reference-answer">
              正确答案：<span
                v-html="questionAnswerAnalysis.standardAnswerKey"
              ></span>
            </b-row>
            <b-col
              cols="12"
              v-html="replaceAnalysis(questionAnswerAnalysis.answerAnalysis)"
            ></b-col>
          </b-row>
        </div>
        <b-row v-else>
          <b-col cols="12" class="reference-answer">
            正确答案：<span
              v-html="trainingTest.question.standardAnswerKey"
            ></span>
          </b-col>
          <b-col
            cols="12"
            v-html="replaceAnalysis(trainingTest.answerAnalysis)"
          ></b-col>
        </b-row>
      </b-col>
    </b-row>

    <GlobalPopup
      :popupsTitle="cancleTestTitle"
      :popupsbuttomA="cancleTestA"
      :popupsbuttomB="cancleTestB"
      @NObutton="submitAnswer"
      @OKbutton="
        () => {
          modal1 = false;
        }
      "
      :isShow="modal1"
    ></GlobalPopup>
  </SprintAnswerBackground>
</template> 

<script>
import PersonalNotes from "@/components/personal_notes/personal_notes";
import SprintAnswerBackground from "@/components/backgroung/sprint-answer-background.vue";
import EnglishTestQuestion from "@/components/englinsh-test/english-test-question.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import { mapMutations, mapState } from "vuex";
import { getTrainingQuestion, saveTrainingAnswer } from "@/api/english-small";
import { SaveRedoAnswer } from "@/api/english-entry";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import GlobalAnswerStates from "../../components/global-popup/global-answer-states";
import GlbalTopicPopover from "../../components/global-popup/glbal-topic-popover.vue";

const defaultData = {
  question: "",
  engTrainingProgresses: [],
  questionScore: 0,
  isSeeAnswer: false,
  answer: "",
  isSecondAnswer: false,
  canNext: false,
  groupAnswerStates: [],
  answerCount: 0,
  errAnswer: "",
  questionInfos: [],
  submiting: false,
  items: [],
  modal1: false,
  cancleTestTitle: "",
  cancleTestA: "",
  cancleTestB: "",
  isAnswerKeyClass: false,
  isAnswerKeyShow: false,
  questionTypeName: "",
};
export default {
  data() {
    return {
      ...defaultData,
      trainingAnswer: "",
      showTip: false,
      tipTitle: "",
      imgs: {
        1: require("@/assets/images/icon-zhengque@2x.png"),
        3: require("@/assets/images/icon-cuowu@2x.png"),
        0: require("@/assets/images/icon-kongbai@2x.png"),
      },
      // mediaUrls: []
    };
  },
  props: {
    isRedo: {
      default: false,
      type: Boolean,
    },
    mediaUrls: {
      default: function () {
        return [];
      },
    },
  },
  components: {
    SprintAnswerBackground,
    EnglishTestQuestion,
    GlobalButton,
    GlobalPopup,
    PersonalNotes,
    GlobalAnswerStates,
    GlbalTopicPopover,
  },
  computed: {
    ...mapState([
      "trainingData",
      "trainingTest",
      "userAnswers",
      "trainingBeginTime",
      "currentModuleId",
      "logDetail",
      "questionNumber",
    ]),
    showLog() {
      return this.$route.meta.showLog;
    },
  },
  created() {
    // debugger;
    this.init();
  },
  activated() {
    // debugger;
    this.init();
  },
  methods: {
    ...mapMutations([
      "setTrainingData",
      "setTrainingTest",
      "setUserAnswers",
      "setTrainingBeginTime",
      "setListOfWrongQuestions",
      "setTrainingAnswer",
    ]),
    seeAnswer(isShow) {
      this.isSeeAnswer = isShow;
    },
    checkSumit() {
      if (this.question.questionType == 22) {
        let inputs = document.getElementsByClassName("fill-blank");
        for (let a of inputs) {
          if (a.value) {
            this.submitAnswer();
            return;
          }
        }
        this.openCheckAnswer();
        return;
      }
      //检查是否填写答案
      console.log("this.answer", this.answer);
      if (!this.answer) {
        this.openCheckAnswer();
      } else {
        this.submitAnswer();
      }
    },
    openCheckAnswer() {
      this.cancleTestTitle = "你未输入任何答案，确定提交？";
      this.cancleTestA = "确定提交";
      this.cancleTestB = "再想想";
      this.modal1 = true;
    },
    resetData() {
      this.canNext = false;
      this.answer = this.errAnswer = "";
      // this.setUserAnswers('')
      const { questions } = this.$parent.detail;
      this.question = questions[0];
      if (this.logDetail.classB == "") {
        this.questionTypeName = this.logDetail.classA;
      } else {
        this.questionTypeName =
          this.logDetail.classA + "-" + this.logDetail.classB;
      }
    },
    init() {
      if (this.showLog) {
        this.questionInfos = this.logDetail.questions;
        this.isAnswerKeyShow = true;
      }
      if (this.isRedo) {
        this.questionInfos = this.logDetail.questions;
      }
      if (this.$route.query.type == 1) {
        this.questionTypeName = "词汇训练";
      } else {
        this.questionTypeName =
          "语法与功能" + "-" + this.$route.query.trainingExamName;
      }
      if (this.isRedo || this.showLog) {
        this.resetData();
        const {
          questionClass: type,
          difficulty: diffecter,
          questions,
        } = this.logDetail;
        const questionScore = questions.reduce(
          (a, b) => a.questionScore + b.questionScore,
          { questionScore: 0 }
        );

        this.groupAnswerStates = [{ colorState: 0 }];
        this.items = [{ score: questionScore, type, diffecter }];
        this.engTrainingProgresses = this.listOfWrongQuestions.map(() => ({
          colorState: 0,
        }));

        return;
      }
      if (!sessionStorage.getItem("trainingTest")) {
        this.getQuestion();
      } else {
        this.question = this.trainingTest.question;
        this.engTrainingProgresses = this.trainingTest.engTrainingProgresses;
        this.groupAnswerStates = this.trainingTest.groupAnswerStates;
      }

      if (sessionStorage.getItem("answerCount")) {
        this.answerCount = sessionStorage.getItem("answerCount");
      }
      if (this.answerCount == 1) {
        this.canNext = true;
        this.isSecondAnswer = false;
      }
    },
    changeRadio(checked) {
      this.answer = checked;
      this.setUserAnswers(checked);
    },
    getInput(index, inputValue) {
      this.answer = inputValue;
      this.setUserAnswers(inputValue);
    },
    getInputAnswers() {
      let inputs = document.getElementsByClassName("fill-blank");
      console.log("inputs", inputs);
      let answers = "";
      for (let a of inputs) {
        answers += a.value + " ";
      }
      this.answer = answers;
    },
    //下一题
    nextQuestion() {
      this.isAnswerKeyClass = false;
      this.isAnswerKeyShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if (this.isRedo) {
        this.isSeeAnswer = false;
        sessionStorage.setItem('answerCount',0) 
        this.questionInfos = this.logDetail.questions;
        this.errAnswer = "";
        this.groupAnswerStates = [{ colorState: 0 }];
        return this.$parent.getData().then(() => {
          this.$store.commit("setQuestionNumber", this.questionNumber + 1);
          this.resetData();
        });
      }
      this.getQuestion();
      this.setUserAnswers("");
      this.answer = "";
    },
    getQuestion() {
      let parmeter = this.trainingData;
      getTrainingQuestion(parmeter).then((res) => {
        console.log("训练答题", res);
        this.mediaUrls = res.data.result.mediaUrls
        this.question = res.data.result.question;
        this.engTrainingProgresses = res.data.result.engTrainingProgresses;
        this.groupAnswerStates = res.data.result.groupAnswerStates;
        this.setTrainingTest(res.data.result);
        this.answerCount = 0;
        this.canNext = false;
        this.isSecondAnswer = false;
        this.isSeeAnswer = false;
        this.errAnswer = "";
        this.questionInfos = [];
        this.questionInfos.push(res.data.result.question);
        sessionStorage.setItem("answerCount", this.answerCount);
        this.setTrainingBeginTime(Date.parse(new Date()));
      });
    },

    getTableItems() {
      if (this.isRedo) {
        return this.items;
      }
      return [
        {
          type: this.getQuestionType(this.trainingTest.question.questionType),
          diffecter: this.getDiffcaultName(this.trainingData.difficultyTagId),
          score: this.trainingTest["questionScore"],
        },
      ];
    },
    //提交答案
    submitAnswer() {
      // debugger;
      this.modal1 = false;
      if (this.answerCount == 1) {
        this.isSecondAnswer = true;
      }
      if (this.question.questionType == 22) {
        this.getInputAnswers();
      }
      let traningTime =
        (Date.parse(new Date()) - this.trainingBeginTime) / 1000;
      if (traningTime < 0) {
        traningTime = 0;
      }
      if (traningTime > 600) {
        traningTime = 600;
      }
      // 重做
      if (this.isRedo) {
        this.submiting = true;
        this.isAnswerKeyShow = true;
        SaveRedoAnswer([
          {
            answer: this.answer, // 答案
            questionId: this.redoQuestion.questionId,
            isSeeAnswer: this.isSeeAnswer, // 学生做题时是否查看答案
            examId: this.examId,
            IsRedo: true,  // 是否重做
            IsSelfRating: false, // 是否自评分
            answerSecond: traningTime,
            selfScore:0,
            SprintId:this.currentModuleId
          },
        ])
          .then((res) => {
            this.submiting = false;
            if (res.data.success) {
              this.setTrainingAnswer(res.data.result[0]);
              // this.listOfWrongQuestions.shift()
              this.$store.dispatch("completeredoQuestion");
              const { isCorrect } = res.data.result[0];
              const colorState = isCorrect ? 1 : 3;
              this.groupAnswerStates = [{ colorState }];

              const index = this.engTrainingProgresses.findIndex(
                (item) => item.colorState === 0
              );
              this.engTrainingProgresses[index].colorState = colorState;
              this.answerCount++;
              sessionStorage.setItem("answerCount", this.answerCount);
              this.$refs.group.point(
                '1',
                this.answerCount
              );
              if (!isCorrect) {
                this.errAnswer = this.answer;
              }
              this.canNext = true;
            }
          })
          .catch(() => {
            this.submiting = false;
          });
        return;
      }

      

      let parmeter = {
        trainingExamId: this.$route.query.trainingExamId,
        summaryId: this.trainingData.summaryId,
        tagId: this.trainingData.tagId,
        grammerTagId: this.trainingData.grammerTagId,
        setTagId: this.trainingData.setTagId,
        difficultyTagId: this.trainingData.difficultyTagId,
        isSeeAnswer: this.isSeeAnswer, // 取消二次答题，传false
        questionId: this.question.questionId,
        answer: this.answer,
        answerSecond: traningTime,
        mode: this.getMode(),
        isSecondAnswer: this.isSecondAnswer,
        maxNumber:this.trainingTest.maxNumber
      };
      this.submiting = true;
      saveTrainingAnswer(parmeter)
        .then((res) => {
          // debugger;
          console.log("提交训练答案", res);
          this.submiting = false;
          this.trainingAnswer = res.data.result;
          this.setTrainingAnswer(res.data.result);
          if (res.data.result.isSubmit) {
            this.trainingData["questionId"] = res.data.result.nextQuestionId;
            this.trainingData["summaryId"] = res.data.result.summaryId;
            this.setTrainingData(this.trainingData);
            console.log("this.trainingData", this.trainingData);
          }
          this.answerCount++;
          sessionStorage.setItem("answerCount", this.answerCount);
          if (res.data.result.isAnswerKey) {
            this.$set(
              this.groupAnswerStates[res.data.result.currentGroupId - 1],
              "colorState",
              1
            );
          } else {
            this.$set(
              this.groupAnswerStates[res.data.result.currentGroupId - 1],
              "colorState",
              3
            );
            this.isAnswerKey = this.userAnswers;
          }
          if (this.answerCount == 1 || res.data.result.isAnswerKey) {
            this.canNext = true;
          }
          if (res.data.result.isFinish) {
            if (this.$route.query.mode === "free") {
              this.$router.replace({
                name: "SmallModuleFreeList",
                query: {
                  // module: 1,
                },
              });
            } else {
              this.$router.replace({
                name: "SmallModuleTrainingQuestionsResults",
                query: {
                  // module: 1,
                  summaryId: this.trainingData.summaryId,
                  type: this.$route.query.type,
                  trainingExamName: this.$route.query.trainingExamName,
                },
              });
            }
          }
          this.setTrainingBeginTime(Date.parse(new Date()));
          this.$refs.group.point(
            res.data.result.currentGroupId,
            this.answerCount
          );
          if (this.answerCount == 1 || this.trainingAnswer.isCorrect) {
            this.isAnswerKeyShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.submiting = false;
        });
    },
    getMode() {
      if (this.$route.query.mode == "free") {
        return "X";
      }
      return "C";
    },
    backTraining() {
      if (this.$route.query.mode === "free") {
        this.$router.replace({
          name: "SmallModuleFreeList",
          query: {
            // module: 1,
          },
        });
        return;
      }
      let name = "";
      if (this.$route.query.type == 1) {
        name = "SmallModuleWordList";
      }
      if (this.$route.query.type == 2) {
        name = "SmallModuleGrammarList";
      }
      this.$router.replace({
        name: name,
        query: {
          // module: 1,
        },
      });
    },
  },

  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("trainingData");
    sessionStorage.removeItem("trainingTest");
    sessionStorage.removeItem("trainingBeginTime");
    sessionStorage.removeItem("trainingAnswer");
    next();
  },
};
</script>

<style scoped lang="scss">
.question-stem-number {
  // background: #2cb774;
  // border-radius: 5px;
  // color: #fff;
  // width: 40px;
  height: 25px;
  // line-height: 25px;
  // text-align: center;
  margin-right: 16px;
  background: #2cb774;
  border-radius: 5px;
  color: #fff;
  padding: 0.15rem 0.6rem;
  // margin-top: 15px;
}
.icon-jiantou_xia {
  font-size: 12px;
}
.view-all {
  color: #2cb774;
  cursor: pointer;
}
.answer-analysis {
  min-height: 9rem;
  text-align: left;
  padding: 1rem 0.5rem;
  border-top: 1px solid rgb(240, 240, 240);
}
.analysis-class {
  padding-top: 2rem;
  height: 6rem;
  overflow: hidden;
  h3 {
    font-size: 1rem !important;
  }
  .reference-answer {
    padding-bottom: 1rem;
    padding-left: 1rem;
    margin: 0;
    display: flex;
    p {
      margin-bottom: 0 !important;
    }
  }
}
.isAnswerKey-class {
  height: 100%;
}
.text-left {
  text-align: left;
  padding: 0;
}
.answer-title {
  padding: 12px 0 15px 20px;
  color: #2cb774;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
}
.text-right {
  padding-right: 5rem;
  button {
    margin: 0 1rem;
    padding: 0.4rem 1.6rem;
    font-size: 16px;
  }
  .disabled {
    color: #333 !important;
    font-weight: bold;
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5 !important;
  }
  .round-text {
    background-color: #fdcb41;
    border: none;
    outline: none;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
  }
  .round {
    font-weight: bold;
  }
  .round:not(:disabled):not(.disabled):active,
  .round:not(:disabled):not(.disabled).active,
  .show > .round.dropdown-toggle {
    color: #fff;
    background-color: #2cb774;
    border-color: #2cb774;
  }
  .round-text:not(:disabled):not(.disabled):active,
  .round-text:not(:disabled):not(.disabled).active,
  .show > .round-text.dropdown-toggle {
    color: #fff;
    background-color: #fdcb41;
    border-color: #fdcb41;
  }
}
</style>
<style lang="scss">
.analysis-class {
  h3,
  h4 {
    font-size: 1rem !important;
  }
  .reference-answer {
    p {
      margin-bottom: 0 !important;
    }
  }
}
</style>
