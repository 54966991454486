<template>
  <b-container
    :class="$route.query.type == 24 ? 'composition-margin' : 'subtopic-margin'"
  >
    <b-row v-if="examQuestion.questionType !== 22">
      <!-- v-if="currentModuleId != 4 && showLog" -->
      <el-row
        :gutter="10"
        class="log-row"
        v-if="showLog && currentModuleId == 2"
      >
        <span class="answer-record">答题记录：</span>
        <el-col :span="6">
          <el-select
            v-model="timer"
            placeholder="请选择答题记录时间"
            @change="change"
          >
            <el-option
              v-for="(item, key) in logDetail.answers"
              :key="item[0].creationTime"
              :label="renderLaber(item[0].isRedo, item[0].isPractice, key)"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-col>
        <span class="tag-type">请先选择左侧答题记录时间</span>
        <div v-show="timer" class="score-bar" style="margin-left: 1rem">
          <span>总分：</span>（<span class="score">{{ score }}</span> /
          <span class="full-score">{{ examQuestion.questionScore }}</span
          >）
        </div>
      </el-row>
      <b-col class="question-statement" v-if="examQuestion.statement" cols="12">
        <span v-if="isVariation" v-show="isNumber()" class="question-number">
          {{ examQuestion.variation + ".  " }}&nbsp;&nbsp;
        </span>
        <!-- 选择填空 -->
        <div
          style="display: flex; justify-content: start; align-items: baseline"
        >
          <div
            v-html="examQuestion.statement ? examQuestion.statement : ''"
          ></div>
        </div>
      </b-col>

      <span
        style="margin: 0 -1.5rem 0 1.5rem; font-weight: bold"
        v-if="!examQuestion.statement"
        >{{ examQuestion.variation + ". " }}</span
      >

      <b-col class="question-Options" v-if="!showAnswer">
        <b-form-radio-group
          style="display: flex; flex-wrap: wrap"
          size="lg"
          @change="
            (checked) => {
              changeRadio(checked, examQuestion.questionId);
            }
          "
          v-model="userAnswer"
          :class="examQuestion.questionId"
        >
          <b-col
            md="6"
            cols="12"
            v-for="(option, index) in examQuestion.options"
            :key="index"
            :class="[
              'question-option',
              option.value === errAnswer ? 'redRadio' : '',
            ]"
          >
            <b-form-radio :disabled="showLog" :value="option.value"
              ><span v-html="option.value + '.  ' + option.statement"></span
            ></b-form-radio>
          </b-col>
        </b-form-radio-group>
      </b-col>
      <b-col class="mock-question-option" v-else>
        <b-form-radio-group
          style="display: flex; flex-wrap: wrap"
          size="lg"
          @change="
            (checked) => {
              changeRadio(checked, examQuestion.questionId);
            }
          "
          v-model="examQuestion.userAnswerKey"
        >
          <b-col
            md="6"
            cols="12"
            v-for="option in examQuestion.options"
            :key="option.value"
            class="question-option"
          >
            <!--             correct-radio 对应正确选择框  correct-text 正确选择框后的文本-->
            <b-form-radio
              :class="[
                option.value === examQuestion.userAnswerKey &&
                option.value === examQuestion.standardAnswerKey
                  ? 'correct-radio'
                  : option.value !== examQuestion.userAnswerKey
                  ? ''
                  : 'error-radio',
              ]"
              :disabled="showAnswer"
              :value="option.value"
            ></b-form-radio>
            <span
              :class="[
                option.value === examQuestion.standardAnswerKey
                  ? 'correct-text'
                  : option.value !== examQuestion.userAnswerKey
                  ? ''
                  : 'error-text',
              ]"
              >{{ option.value + "." }}</span
            >
            <span>{{ option.statement }}</span>
            <span
              v-if="option.value === examQuestion.standardAnswerKey"
              class="iconfont icon-duihao"
            ></span>
          </b-col>
        </b-form-radio-group>
      </b-col>
      <slot name="literacyImprovement"> </slot>
    </b-row>
    <b-row v-if="examQuestion.questionType === 22">
      <b-col>
        <el-row :gutter="10" v-if="currentModuleId != 4 && showLog">
          <el-col :span="24" class="log-row">
            <span class="answer-record">答题记录：</span>
            <el-col :span="6">
              <el-select
                v-model="timer"
                placeholder="请选择答题记录时间"
                @change="change"
              >
                <el-option
                  v-for="(item, key) in logDetail.answers"
                  :key="item[0].creationTime"
                  :label="renderLaber(item[0].isRedo, item[0].isPractice, key)"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-col>
            <span class="tag-type">请先选择左侧答题记录时间</span>
            <el-col class="score-bar" :span="6" v-show="timer"
              ><span>总分：</span>（<span class="score">{{ score }}</span> /
              <span class="full-score">{{ examQuestion.questionScore }}</span
              >）</el-col
            >
          </el-col>
        </el-row>
      </b-col>
      <b-col class="question-statement" v-if="examQuestion.statement" cols="12">
        <span v-if="isVariation" class="question-number">
          {{ examQuestion.variation + ".  " }}&nbsp;&nbsp;
        </span>
        <div
          :class="examQuestion.questionId"
          v-html="
            showLog
              ? showAnswerStatement
                ? showAnswerStatement
                : examQuestion.statement
              : examQuestion.statement
              ? examQuestion.statement
              : ''
          "
          @input="getInput"
        ></div>
        <!--          (examQuestion.statement ? examQuestion.statement : "")-->
        <!--        }}-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import filterHtml from "@/utils/processingHTML";

export default {
  data() {
    return {
      userAnswer: "",
      timer: "",
      showAnswer: false,
      showAnswerStatement: "",
      score: 0,
      isAnswerKeyClass: false,
      notEnabled: true,
    };
  },
  created() {
    if (this.showLog) this.showAnswer = false;
    // console.info(this);
  },
  activated() {
    if (this.showLog) this.showAnswer = false;
  },
  computed: {
    ...mapState([
      "simulationExamQuestions",
      "userAnswers",
      "userCorrectAnswer",
      "logDetail",
      "currentModuleId",
    ]),
    showLog() {
      const showLog = this.$route.meta.showLog;

      return showLog;
    },
  },
  props: {
    examQuestion: {
      default: () => {
        return {};
      },
    },
    showTheAnswer: {
      type: Boolean,
      default: false,
    },
    setionId: {
      default: "",
    },
    answer: {
      default: "",
    },
    index: {
      default: "",
    },
    isVariation: {
      default: true,
    },
    errAnswer: {
      default: "",
    },
    isSelfScores: {
      default: function () {
        return [];
      },
    },
    questionIndex: {
      default: "",
    },
    answerCount: {
      default: "",
    },
  },
  watch: {
    showTheAnswer: {
      handler(newVal) {
        this.showAnswer = newVal;
      },
      immediate: true,
    },
    examQuestion: {
      handler() {
        // console.info(this);
        // this.userAnswer = "";
      },
      deep: true,
      immediate: true,
    },
    answer: {
      handler(value) {
        try {
          this.userAnswer = value;
          if (this.examQuestion.questionType === 22) {
            this.$nextTick(() => {
              let els = document.getElementsByClassName(
                this.examQuestion.questionId
              );
              let input = els[0].getElementsByTagName("input")[0];
              input.value = this.userAnswer;
            });
          }
        } catch (e) {
          console.log("e", e);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(["setUserAnswers"]),
    isNumber() {
      if (this.$route.name == "BigModuleTrainingQuestions") {
        if (this.$route.query.type != 24) {
          return true;
        }
      } else if (
        this.logDetail.questionClassId != 2 &&
        this.logDetail.questionClassId != 24
      ) {
        return true;
      }
    },
    change(val, index = 0) {
      this.$nextTick(() => {
        try {
          this.showAnswer = false;
          this.userAnswer = this.examQuestion.userAnswerKey = "";
          const data = this.logDetail.answers[val][index];
          this.$set(this.examQuestion, "userAnswerKey", data.answer);
          this.userAnswer = data.answer;
          this.score = data.score;
          this.showAnswer = true;

          if (this.examQuestion.questionType === 22) {
            data.standardAnswer =
              this.examQuestion.standardAnswer ||
              this.examQuestion.standardAnswerKey;
            this.showAnswerStatement = filterHtml(this.examQuestion.statement, [
              data,
            ]);
          }
        } catch (e) {
          console.error(e);
          this.showAnswer = true;
          this.userAnswer = this.examQuestion.userAnswerKey = "";
        }
      });
    },
    changeRadio(checked, questionId) {
      debugger;
      this.$emit("changeRadio", checked, questionId, this.setionId, this.index);

      try {
        this.userCorrectAnswer.filter((item) => {
          if (
            item.questionId == questionId &&
            item.lastAnswer == checked &&
            item.isCorrect == false
          ) {
            let el = document.getElementsByClassName(item.questionId);
            el[0].classList.add("redRadio");
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    getInput(event) {
      this.$emit("getInput", this.index, event.target.value);
    },

    radioClass() {
      this.userCorrectAnswer.filter((item) => {
        if (!item.isCorrect) {
          let el = document.getElementsByClassName(item.questionId);
          el[0].classList.add("redRadio");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-type {
  color: #2cb774;
  font-weight: 700;
  background-color: #f4f4f5;
  border: 1px solid #e9e9e9;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 15px;
}
.view-all {
  color: #2cb774;
  cursor: pointer;
}
.answer-analysis {
  min-height: 9rem;
  text-align: left;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid rgb(240, 240, 240);
}
.analysis-class {
  padding-top: 2rem;
  height: 5.5rem;
  overflow: hidden;
  h3 {
    font-size: 1rem !important;
  }
}
.isAnswerKey-class {
  height: 100%;
}
.subtopic-margin {
  display: contents;
  padding-bottom: 1rem;
  padding: 0;
}
.composition-margin {
  padding: 0;
}
.question-stament {
  line-height: 2;
}
.question-option {
  text-align: left;
  display: flex;
  padding-bottom: 0.5rem;
  .custom-control-inline {
    font-size: 1rem !important;
    line-height: 2 !important;
    margin: 0;
    padding: 0;
    span {
      display: flex;
    }
  }
}
.question-statement {
  display: flex;
  text-align: left;
  line-height: 2;
  font-weight: bold;
  // padding: 0;
  // padding-left: 15px;
}
.test-item .col-md-6 > span:nth-child(2) {
  line-height: 2;
  padding-right: 5px;
}
.question-Options {
  padding-left: 3.8rem;
}
.question-number {
  padding-right: 4px;
}
.mock-question-option {
  padding-left: 3.7rem;
  .question-option {
    padding-bottom: 1.5rem;
  }
  .icon-duihao {
    color: #2cb774;
    line-height: 1.5;
    font-size: 18px;
    padding-left: 5px;
    font-weight: bold;
  }
}
.log-row {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: $text-light-gray;
  font-weight: bold;
  .answer-record {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.score-bar {
  text-align: left;
  color: $text-light-gray;

  span {
    font-weight: bold;
    color: $text-green;
  }
  .full-score {
    color: $text-light-gray;
  }
}
</style>
<style lang="scss" >
.correct-radio {
  .custom-control-label::before {
    background-color: #2cb774 !important;
    border-color: #2cb774 !important;
  }
}
.greenRadio {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #2cb774 !important;
    border-color: #2cb774 !important;
  }
}
.redRadio {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
  }
}
.error-radio {
  .custom-control-label::before {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
  }
}
.correct-text,
.correct-text + span {
  color: #2cb774 !important;
  font-weight: bold !important;
}
.error-text,
.error-text + span {
  color: #cc0000 !important;
}
.custom-radio {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2cb774;
    background-color: #2cb774;
  }
}
</style>
