export  function getText(html) {
  const dom = document.createElement("div");
  dom.innerHTML = html;
  return dom.innerText
}
export default function  filterHtml(html, arr) {
  console.log('arr',arr)

  const dom = document.createElement("div");
  dom.innerHTML = html;
  const inputList = dom.getElementsByTagName("input");
  Array.prototype.forEach.call(inputList, (item, i) => {
    const span = document.createElement("span");
    let color = "#CC0000";
    let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${getText(arr[i].standardAnswer)}</span>）</span>
        `;
    if (arr[i].isCorrect) {
      standardAnswerKeySpan = "";
      color = "#2CB774";
    }
    span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr[i].answer}${standardAnswerKeySpan}</span>`;
    item.value = arr[i].standardAnswer;
    item.style.display = "none";
    item.setAttribute("attr_name", "attr_value" + i);
    item.parentNode.insertBefore(span, item);
    // item.parent.removeChild(item)
    // this.filterHtml(html, arr)
  });
  return dom.innerHTML;
}
